import {domainUrl} from "../../helper";
import {IBlogDataPost} from "../../types/pages";
import {ReactNode} from "react";
import {schemaCategoriesType} from "../../types/header";
import {IPostData} from "../../types/posts";

const logoPath = `/stores-logo.svg`;

export const schemaNavLinks = (dbName: string, asPath: string, menuList: any[]) => {
    return {
        "@context": "https://schema.org/",
        "@type": "Menu",
        "url": domainUrl(dbName + asPath),
        "name": "MenuItems",
        "description": "Company menu items description",
        "hasMenuSection": menuList.map((item: any, index: number) => ({
            "@type": "MenuSection",
            "position": index + 1,
            "hasMenuItem": {
                "@type": "MenuItem",
                "name": item?.name,
            },
        })),

    };
}

export const schemaBlog = (domain: string, dbName: string, asPath: string, blogs: any[], forBlogType: string = "blog") => {
    return {
        "@context": `https://schema.org/`,
        "@type": "Blog",
        "name": "Blogs",
        "url": domainUrl(dbName + asPath),
        "offers": blogs.map((post: IBlogDataPost) => ({
            "@type": "Product",
            "id": post.id,
            "name": forBlogType === "blog" ? post.blog_title : post.name,
            "description": forBlogType === "blog" ? post.html_content : post.short_description,
            "image": domainUrl(`${domain}/storage/${dbName}/${logoPath}`),
        }))
    }
}

export const schemaPost = (domain: string, dbName: string, asPath: string, posts: any[]) => {
    return {
        "@context": `https://schema.org/`,
        "@type": "Post",
        "name": "Posts",
        "url": domainUrl(dbName + asPath),
        "offers": posts.map((post: IPostData) => ({
            "@type": "Product",
            "id": post.id,
            "name": post.name,
            "description": post.short_description,
            "image": domainUrl(`${domain}/storage/${dbName}/${logoPath}`),
        }))
    }
}

export const schemaPages = (dbName: string, asPath: string, content: any) => {
    return {
        "@context": `https://schema.org/`,
        "@type": "Page",
        "name": "Pages",
        "url": domainUrl(dbName + asPath),
        "meta_description": content?.data?.[0].meta_description,
        "meta_keywords": content?.data?.[0].meta_keywords,
        "meta_title": content?.data?.[0].meta_title,
        "html_content": content?.data?.[0].html_content,
    };
}

export const schemaCategoryPage = (productsList: any, dbName: string) => {
    return {
        "@context": `https://schema.org/`,
        "@type": "Product",
        "name": `Products in Category`,
        "offers": productsList['data'].map((product: any) => ({
            "@type": "Product",
            "id": product?.id,
            "name": product?.flats?.[0]?.name,
            "description": product?.flats?.[0]?.description,
            "image": domainUrl(`${dbName}/storage/${product?.images?.[0]}`),
        }))
    }
}

export const schemaProduct = (asPath: any, product: any, dbName: string, domain: string) => {

    let brand = product.details.find((el: any) => el.attribute[0]?.code === "brand")?.attribute_value

    return {
        "@context": `https://schema.org/`,
        "@type": "Product",
        "name": product?.flats[0]?.name,
        // "name": product?.product_flat[0]?.name,
        "description": product?.flats[0]?.meta_description && product?.flats[0]?.description,
        // "description": product?.product_flat[0]?.meta_description && product?.product_flat[0]?.description,
        "image": domainUrl(`${dbName}/storage/${product?.images[0]?.path}`),
        // "image": domainUrl(`${dbName}/storage/${product?.product_images[0]?.path}`),
        "url": domainUrl(`${dbName}${asPath}`),
        "brand": {
            "@type": "Brand",
            "name": brand || domain,
            // "logo": domainUrl(`${dbName}/storage/${domain}/configuration/logo/logo.webp`),
        },
        "review": {
            "@type": "Review",
            "name": "Organization Review",
            "reviewRating": {
                "@type": "Rating",
                "ratingValue": "0"
            },
            "author": {
                "@type": "Organization",
                "name": domain
            },
        },
        "productID": product?.id,
        // "sku": product?.product_flat[0]?.sku,
        "sku": product?.sku,
    };
}

export const schemaProducts = (asPath: any, products: any, dbName: string) => {
    return {
        "@context": `https://schema.org/`,
        "@type": "Product",
        "name": "Products",
        "url": domainUrl(dbName + asPath),
        "offers": products.map((product: any) => ({
            "@type": "Offer",
            // "sku": product?.product_flat[0]?.sku,
            "sku": product?.flats[0]?.sku,
            // "image": domainUrl(`${dbName}/storage/${product?.product_images[0]?.path}`),
            "image": domainUrl(`${dbName}/storage/${product?.images[0]}`),
            // "name": product.product_flat[0].name,
            "name": product.flats[0].name,
            // "description": product?.product_flat[0]?.meta_description || product?.product_flat[0]?.description,
            "description": product?.flats[0]?.meta_description || product?.flats[0]?.description,
            // "price": product?.product_flat[0]?.special_price || product?.product_flat[0]?.price,
            "price": product?.flats[0]?.special_price || product?.flats[0]?.price,
        }))
    };
}

type departItemType = {
    description: string;
    meta_description: string;
    name: ReactNode;
    children: string | departItemType[];
}
export const schemaCategories: (e: {
    children: departItemType[] | string
}[], url: string) => schemaCategoriesType = (depart, url) => {
    return {
        "@context": `https://schema.org/`,
        "@type": "Menu",
        "url": url,
        "name": "Categories",
        "description": "Categories description",
        "hasMenuSection": (depart?.[0]?.children as departItemType[])?.map((item: departItemType) => ({
            "@type": "MenuSection",
            "name": "Categories menu section",
            "position": depart?.indexOf(item) + 1,
            "hasMenuItem": {
                "@type": "MenuItem",
                "name": item.name,
                "description": item.meta_description || item.description,
            },
        }))
    }
};

export const schemaString = (title: any, description: any) => {
    return {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "name": "Accordion",
        "mainEntity": [
            {
                "@type": "Question",
                "name": title,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": description
                }
            }
        ]
    }
}

export const schemaSiteHeader = (dbName: string, asPath: string) => {
    return {
        "@context": `https://schema.org/`,
        "@type": "ItemList",
        "name": "Header Items",
        "description": "contact, change currency and language",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "name": "Telephone"
            },
            {
                "@type": "ListItem",
                "position": 2,
                "name": "Currency"
            },
            {
                "@type": "ListItem",
                "position": 3,
                "name": "Language"
            },
        ],
        url: domainUrl(dbName + asPath)
    }
}

export const schemaPageHeader = (dbName: string, asPath: string, breadcrumb: any) => {
    return {
        "@context": `https://schema.org/`,
        "@type": "BreadcrumbList",
        "name": "Breadcrumb",
        "url": domainUrl(dbName + asPath),
        "itemListElement": breadcrumb.map((item: any) => ({
            "name": "Breadcrumb",
            "@type": "ListItem",
            "position": breadcrumb.indexOf(item) + 1,
            "item": {
                "url": `https://${dbName}${item.url}`,
            },
        })),
    }
}