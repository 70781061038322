import React, {FC} from "react"
import cls from "../home/homePage.module.scss"

interface IProps {
    translationsData: { [key: string]: string }
}

const HomeIntro: FC<IProps> = ({translationsData}) => {
    return (
        <div className="container">
            <div className={cls["welcome-title"]}>
                <h1>{translationsData.HomeHeading}</h1>
                <p>{translationsData.HomeIntro}</p>
            </div>
        </div>
    )
}

export default HomeIntro