import cls from "./../products.module.scss";
import React, {FC, useEffect, useRef, useState} from "react";
import BlockHeader from "./../BlockHeader";
import {IProduct, TranslationsType} from "../../../../types/homePageTypes";
import ProductCard from "./../ProductCard";

export interface IBlockProductProps {
    products: Array<IProduct>;
    dbName: string;
    translationsData: TranslationsType;
    backOrderValue: string;
    selectedCurrItems: {
        selectedCurrCode: string;
        selectedCurrRate: string;
        selectedCurrSymbol: string
    };
    selectedLocale: string;
    isMobile: boolean
}

const ThirdProducts: FC<IBlockProductProps> = ({products, isMobile, ...pageProps}) => {
    const ref = useRef<HTMLDivElement>(null)
    const [originalProducts, setOriginalProducts] = useState<boolean>(false);
    const handleBlockTitle = {
        true: {
            true: <div>
                <BlockHeader title={products[0]?.block_title}/>
            </div>,
            false: null
        },
        false: {
            true: null,
            false: null
        }
    }
    useEffect(() => {
        const handleScroll = () => {
            if (ref && ref.current) {
                setOriginalProducts(window.scrollY >= ref.current?.offsetTop - 1000)
            }
        }
        if (!originalProducts && isMobile) {
            window.addEventListener("scroll", handleScroll)
        } else {
            window.removeEventListener("scroll", handleScroll)
        }
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])
    return (
        <div className={`container ${cls["home-product-container"]}`}>
            {handleBlockTitle[`${!!products.length}`][`${!!products[0]?.block_title}`]}
            <div className={cls["block-products__body"]}>
                <div className={cls["block-products__list"]}>
                    {(isMobile && !originalProducts ? products.filter((_, indexFil) => indexFil < 2) : products).map((product: IProduct, index: number) => {
                        return (
                            <div key={product.id} style={{minHeight: 200}} className={cls["product-card-parent"]}>
                                <div ref={!originalProducts ? ref : null} className={cls["block-products__list-item"]}>
                                    <ProductCard isMobile product={product} priority={false} loading="lazy" {...pageProps} />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default ThirdProducts;
